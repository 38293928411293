import React, { FunctionComponent } from 'react';
import { classNames } from '../../utilities/classNames';

interface Props {
  size?: number;
  color?: string;
  thickness?: number;
  className?: string;
}

export const LoadingIndicator: FunctionComponent<Props> = ({
  size = 16,
  color,
  thickness,
  className,
}) => {
  return (
    <span
      className={classNames(className, 'flex justify-center items-center')}
      data-cy="loading-indicator"
    >
      <span
        className={classNames(
          `animate-spin rounded-full border-${color}`,
          `h-${size} w-${size} border-b-${
            thickness ? thickness : Math.ceil(size / 8) * 2
          }`,
        )}
      />
    </span>
  );
};

LoadingIndicator.defaultProps = {
  color: 'accent-600',
};
